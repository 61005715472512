<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Verify it's you</h1>
            <b-form @submit="validateUserIdentity" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                {{formErrorMessage()}}
              </div>

              <b-form-group label-for="code" class="my-2">
                <p class="text-center text-muted p-0 m-0 pb-2 px-1">Tell us a little bit about yourself</p>
                <p class="text-center text-muted small p-0 m-0 pb-2 px-1">The email must match the one you gave us during your initial request.</p>

                <b-input-group>
                  <b-form-input v-model="$v.input.email.$model" type="text" placeholder="Email Address" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.email.$invalid">
                  <span v-if="!$v.input.email.required">Email is required</span>
                </div>
              </b-form-group>

              <b-btn :disabled="isSubmitting" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Verify</span>
                <div v-if="isSubmitting" v-bind:class="{'local-spinner-border':isSubmitting, 'local-spinner-spacer':!isSubmitting}"/>
              </b-btn>
            </b-form>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application'
import FormMixin from '@/mixins/formMixin'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'validateIdentity',
  mixins: [FormMixin],
  data: function() {
    return {
      input: {
        email: '',
      },
      timeout: null,
      isSubmitting: false
    }
  },
  validations: {
    input: {
      email: {
        required
      }
    }
  },
  methods: {
    onTimeout: function() {
      this.isSubmitting = false;
    },

    validateUserIdentity: function() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      this.timeout = setTimeout(this.onTimeout, 5000);

      this.submit('post', ApiProperties.api.validateUserIdentity, {}, this.input, (response) => {
          const verificationMethod = response.data.authMethod;
          const phoneLastFourDigits = response.data.phoneLastFourDigits;
          const routeName = (verificationMethod === 'SMS') ? 'verifyIdentityBySMS' : 'verifyIdentityByEmail';

          this.$router.push({ name: routeName, params: { identityId: response.data.identityId, phoneLastFourDigits: phoneLastFourDigits } });
        },
        () => { // error in authenticate
          this.isSubmitting = false;
        }
      )
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  }
}
</script>
<style lang="scss">
@import '../scss/login-pages';

</style>
